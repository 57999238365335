import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle, Settings } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';
import { Button } from './components/ui/button';

const LocationPermissionChecker = () => {
  const [permissionStatus, setPermissionStatus] = useState('checking');
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    checkPermission();
    detectIOS();
  }, []);

  const detectIOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  };

  const checkPermission = async () => {
    if ('permissions' in navigator && 'geolocation' in navigator) {
      try {
        const permission = await navigator.permissions.query({ name: 'geolocation' });
        setPermissionStatus(permission.state);
        permission.onchange = () => setPermissionStatus(permission.state);
      } catch (error) {
        console.error('Error checking permission:', error);
        setPermissionStatus('error');
      }
    } else {
      setPermissionStatus('unsupported');
    }
  };

  const requestPermission = () => {
    console.log('Requesting permission...');
    navigator.geolocation.getCurrentPosition(
      () => setPermissionStatus('granted'),
      () => setPermissionStatus('denied')
    );
  };

  const openSettingsGuide = () => {
    if (isIOS) {
      // iOS設定アプリの位置情報サービスページを開くURLスキーム
      window.location.href = 'app-settings:root=Privacy&path=LOCATION';
    } else {
      alert('Androidデバイスの場合:\n1. ホーム画面に戻る\n2. 設定アプリを開く\n3. アプリと通知 > アプリ情報\n4. このアプリを探し、権限 > 位置情報を選択');
    }
  };

  const renderContent = () => {
    switch (permissionStatus) {
      case 'granted':
        return (
          <Alert className="permission-alert" variant="default">
            <CheckCircle className="h-4 w-4" />
            <AlertTitle>位置情報の使用が許可されています</AlertTitle>
            <AlertDescription>
              デバイスとブラウザの両方で位置情報の使用が許可されています。
            </AlertDescription>
          </Alert>
        );
      case 'prompt':
      case 'denied':
        return (
          <Alert className="permission-alert" variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>位置情報の使用が許可されていません</AlertTitle>
            <AlertDescription>
              アプリの機能を十分に活用するには、位置情報の使用を許可してください。
              <Button onClick={requestPermission} className="permission-button">
                位置情報を許可する
              </Button>
              <Button onClick={openSettingsGuide} variant="outline">
                <Settings className="mr-2 h-4 w-4" />
                {isIOS ? '設定を開く' : '設定方法を見る'}
              </Button>
            </AlertDescription>
          </Alert>
        );
      case 'unsupported':
        return (
          <Alert className="permission-alert" variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>位置情報機能がサポートされていません</AlertTitle>
            <AlertDescription>
              お使いのデバイスまたはブラウザで位置情報機能がサポートされていません。
            </AlertDescription>
          </Alert>
        );
      case 'error':
        return (
          <Alert className="permission-alert" variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>エラーが発生しました</AlertTitle>
            <AlertDescription>
              位置情報の許可状態の確認中にエラーが発生しました。
            </AlertDescription>
          </Alert>
        );
      default:
        return (
          <Alert className="permission-alert">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>位置情報の許可状態を確認中...</AlertTitle>
            <AlertDescription>
              しばらくお待ちください。
            </AlertDescription>
          </Alert>
        );
    }
  };

  return (
    <div className="permission-checker-container">
      <h1 className="permission-checker-title text-2xl font-bold mb-4">位置情報の許可状態</h1>
      {renderContent()}
    </div>
  );
};

export default LocationPermissionChecker;